// Conveniently import this file anywhere to use db

import firebase from 'firebase/app'
import 'firebase/firestore'

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    // authDomain: "ty-citizen-336604.firebaseapp.com",
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    // storageBucket: "ty-citizen-336604.appspot.com",
    // messagingSenderId: "258664301005",
    // appId: "1:258664301005:web:c47f84bc2fe1a0ae044484"
};

export const db = firebase
    .initializeApp(firebaseConfig)
    .firestore()


// Export types that exists in Firestore - Uncomment if you need them in your app
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }
